import request from "@/api/request";

class ModelService {
  static async getList({ page, order }) {
    return request.get("/models", { page, order });
  }

  static async getAssociatedModels({ id }) {
    return request.get(`/models/${id}/associated`);
  }

  static async tip({ id, price, message }) {
    return request.post(`/models/${id}/tip`, { price, message });
  }

  static async getModel({ id }) {
    return request.get(`/models/${id}`);
  }

  static async getRandom() {
    return request.get(`/models/random`);
  }

  static async getTopShootings() {
    return request.get(`/models/top_shootings`);
  }

  static async getPublicModels(count) {
    return request.get(`/models/public`, { count });
  }
}

export default ModelService;
