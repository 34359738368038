import modelService from "@/api/services/modelService";
import ModelService from "@/api/services/modelService";

export default () => {
  return {
    namespaced: true,
    state: {
      models: [],
      associatedModels: [],
      publicModels: [],
      list: {},
      listBusy: false,
    },

    getters: {
      getModel: (state) => (id) => state.models[id],
      getAssociatedModels: (state) => (id) => state.associatedModels[id],
      getPublicModels: (state) => state.publicModels,
      getList: (state) => state.list,
      isListBusy: (state) => state.listBusy,
    },

    mutations: {
      SET_MODEL(state, model) {
        const newModel = {};
        newModel[model.id] = model;

        state.models = {
          ...state.models,
          ...newModel,
        };
      },
      SET_ASSOCIATED_MODELS(state, { result, id }) {
        const newAssociatedModels = {};
        newAssociatedModels[id] = result;

        state.associatedModels = {
          ...state.associatedModels,
          ...newAssociatedModels,
        };
      },
      SET_PUBLIC_MODELS(state, models) {
        state.publicModels = models;
      },
      SET_LIST(state, list) {
        state.list = list;
      },
      SET_LIST_BUSY(state, value) {
        state.listBusy = value;
      },
    },

    actions: {
      async getModel({ commit }, { id }) {
        const response = await modelService.getModel({ id });
        commit("SET_MODEL", response);
        return response;
      },
      async getAssociatedModels({ commit, dispatch }, { id }) {
        const response = await modelService.getAssociatedModels({ id });
        commit("SET_ASSOCIATED_MODELS", { result: response, id });
        response.associated_models.forEach((model) => {
          //disabled because result doesnt contain full model json as needed for profile, todo: fix
          // commit("SET_MODEL", model);
          dispatch("gallery/getModelGalleries", { id: model.id }, { root: true });
        });

        return response;
      },
      async getPublicModels({ commit, count = 12 }) {
        const response = await modelService.getPublicModels(count);
        commit("SET_PUBLIC_MODELS", response);
      },
      async getList({ commit, state, dispatch }, params) {
        if (state.listBusy) {
          return;
        }

        commit("SET_LIST_BUSY", true);
        const response = await ModelService.getList({ page: params.page || 1, order: params.order || null });
        response.params = params;
        commit("SET_LIST", response);
        commit("SET_LIST_BUSY", false);
        response.models.forEach((model) => {
          if (state.models[model.id]) {
            return;
          }

          //disabled because result doesnt contain full model json as needed for profile, todo: fix
          // commit("SET_MODEL", model);
          dispatch("gallery/getModelGalleries", { id: model.id }, { root: true });
        });
      },
    },
  };
};
