export const getLink = (update) => {
  if (update.type === "playdate") {
    return "/playdates";
  }

  if (update.link) {
    return update.link;
  }

  if (update.model !== null) {
    let anchor = "";

    if (update.play_video_id !== null) {
      anchor = "#video-" + update.play_video_id;
    } else if (update.gallery_id !== null) {
      anchor = "#gallery-" + update.gallery_id;
    }

    return "/profile/" + update.model.id + anchor;
  }

  return "/update/" + update.id;
};
