import galleryService from "@/api/services/galleryService";

export default () => {
  return {
    namespaced: true,
    state: {
      galleries: [],
      modelGalleries: [],
    },

    getters: {
      getGallery: (state) => (id) => state.galleries[id],
      getModelGalleries: (state) => (id) => state.modelGalleries[id],
    },

    mutations: {
      SET_GALLERIES(state, galleries) {
        const newGalleries = {};
        Object.keys(galleries).forEach((key) => {
          const gallery = galleries[key];
          const { id } = galleries[key];
          newGalleries[id] = gallery;
        });

        if (Object.keys(newGalleries).length > 0) {
          state.galleries = {
            ...state.galleries,
            ...newGalleries,
          };
        }
      },
      SET_MODEL_GALLERIES(state, { modelID, galleries }) {
        const newModelGalleries = {};
        newModelGalleries[modelID] = galleries;

        state.modelGalleries = {
          ...state.modelGalleries,
          ...newModelGalleries,
        };
      },
    },

    actions: {
      async getModelGalleries({ commit }, { id }) {
        const response = await galleryService.getModelGalleries({ id });
        commit("SET_GALLERIES", response);
        commit("SET_MODEL_GALLERIES", {
          modelID: id,
          galleries: response,
        });

        Object.keys(response).forEach((key) => {
          response[key].videos.forEach((video) => {
            commit("video/SET_VIDEO", video, { root: true });
          });
        });

        return response;
      },
      async getGallery({ commit }, { modelId, galleryId }) {
        const response = await galleryService.getModelGallery({ modelId, galleryId });
        commit("SET_GALLERIES", [response]);

        response.videos.forEach((video) => {
          commit("video/SET_VIDEO", video, { root: true });
        });

        return response;
      },
    },
  };
};
