import videoService from "@/api/services/videoService";

export default async ({ to, redirect }) => {
  if (to.path !== "/free-video") {
    return true;
  }

  if (to.query.token) {
    try {
      const videos = await videoService.setUnlockedVideos(to.query.token);

      if (videos.length > 0) {
        const video = videos[0];

        const uri = `/profile/${video.model.id}#video-${video.id}`;
        redirect(uri);
        return;
      }
    } catch (e) {
      // ignore
    }
  }

  redirect("/recent_updates");
};
