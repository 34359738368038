import browserHelper from "./browserHelper";
import { USER_AVATAR_TYPE_OLD } from "@/utils/variables";

export const getMediaUrl = (dir, name) => {
  return `${process.env.VUE_APP_MEDIA_CONTENT_HOST}/${dir}/${name}`;
};

const getThumbnailUrl = (dir, name, options = {}) => {
  options.quality = 85;
  options.sharpen = 1;

  if (browserHelper.isAvifSupported) {
    options.format = "avif";
  }

  const props = [];

  for (const [key, value] of Object.entries(options)) {
    props.push(`${key}=${value}`);
  }

  return `${process.env.VUE_APP_THUMB_HOST}/${props.join(",")}/${dir}/${name}`;
};

export const getProfileAvatar = (profile) => {
  if (profile.avatar === null) {
    return require("@/assets/images/user_profile/default_avatar.svg");
  }

  return profile.avatar === USER_AVATAR_TYPE_OLD ? getMediaUrl("upload/avatars", profile.id + ".svg") : getNewAvatar(profile.avatar + ".svg");
};

export const getNewAvatar = (name) => {
  return getMediaUrl("upload/avatars/new", name);
};

export const getPaymentMethodLogo = (name) => {
  return getMediaUrl("content/images_mysql/images_paymentmethod_logos/backup", name);
};

export const getVideoTimelinePreview = (name) => {
  return getMediaUrl("content/images_mysql/video_timeline_previews", name);
};

export const getWhoWeAreVideoUrl = (lang) => {
  return getMediaUrl(
    "content/videos/about_us/recoded",
    `,160/team_${lang}.mp4,240/team_${lang}.mp4,360/team_${lang}.mp4,480/team_${lang}.mp4,720/team_${lang}.mp4,1080/team_${lang}.mp4,.urlset/master.m3u8`,
  );
};

export const getBehindTheScenesUrl = () => {
  return getMediaUrl(
    "content/videos/about_us/recoded",
    `,160/bts.mp4,240/bts.mp4,360/bts.mp4,480/bts.mp4,720/bts.mp4,1080/bts.mp4,.urlset/master.m3u8`,
  );
};

export const getBehindTheScenesUrlV2 = () => {
  return getMediaUrl(
    "content/videos/about_us/recoded",
    `,160/bts_v2.mp4,240/bts_v2.mp4,360/bts_v2.mp4,480/bts_v2.mp4,720/bts_v2.mp4,1080/bts_v2.mp4,.urlset/master.m3u8`,
  );
};

export const getVideoPoster = (name) => {
  return getThumbnailUrl("content/images_mysql/images_videothumbnails/backup", name, { width: 900, height: 500, fit: "cover" });
};

export const getVideoPosterMini = (name) => {
  return getThumbnailUrl("content/images_mysql/images_videothumbnails/backup", name, { width: 300, height: 167, fit: "cover" });
};

export const getPublicVideoPoster = (name) => {
  return getThumbnailUrl("content/images_mysql/images_videothumbnails_public/backup", name, { width: 900, height: 500, fit: "cover" });
};

export const getVideoThumbnail = (name) => {
  return getThumbnailUrl("content/images_mysql/images_videothumbnails/backup", name, { width: 400, height: 490, fit: "cover" });
};

export const getFreeareaVideoPoster = (name) => {
  return getThumbnailUrl("upload/CMS/freearea/", name, { width: 900, height: 500, fit: "cover" });
};

export const getFreeareaVideoPosterBig = (name) => {
  return getThumbnailUrl("upload/CMS/freearea/", name, { width: 1280, height: 720, fit: "cover" });
};

export const getGalleryImageThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Images/backup", name, { width: 400, height: 400, fit: "cover" });
};

export const getGalleryImageMediumThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Images/backup", name, { width: 600, height: 600, fit: "cover" });
};

export const getGalleryImage = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Images/backup", name, { width: 1920, height: 1080 });
};

export const getUpdateFullThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Recent_Image/backup", name, { width: 750 });
};

export const getUpdateMiniThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Recent_Image/backup", name, { width: 660, height: 440 });
};

export const getUpdateBlogThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Recent_Image/backup", name, { width: 380, height: 1500 });
};

export const getPublicModelThumb = (name, width = 510, height = 660, adaptive = true) => {
  const options = { width, height };

  if (!adaptive) {
    options.fit = "cover";
  }

  return getThumbnailUrl("content/images_mysql/Model_Cover_Image_Unsubscribed/backup", name, options);
};

export const getModelThumb = (name, width = 510, height = 660) => {
  const options = { width, height, fit: "cover" };

  return getThumbnailUrl("content/images_mysql/Model_Cover_Image/backup", name, options);
};

export const getModelThumbMini = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Cover_Image/backup", name, { width: 270, height: 360 });
};

export const getPlaydateCameraThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Playdate_Image/backup", name, { width: 340, height: 440, fit: "cover" });
};

export const getPublicPlaydateItemThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Playdate_Image_Page/backup", name, { width: 450, height: 1500 });
};

export const getCustomNewAndUpcomingThumb = (name) => {
  return getThumbnailUrl("upload/CMS/new_upcoming", name, { width: 510, height: 750 });
};

export const getFacebookMainThumb = (name) => {
  return getThumbnailUrl("content/images_mysql/Model_Images/backup", name, { width: 680, height: 680 });
};

export const getLandingThumb = (name) => {
  return getMediaUrl("upload/CMS/landing_page", name);
};

export const getFreetourImage = (name) => {
  return getMediaUrl("assets/images/freetour_alternative", name);
};

export const getLandingMainThumb = (name) => {
  return getMediaUrl("upload/landings/banners/main", name);
};

export const getTourpageGalleryThumb = (name) => {
  return getMediaUrl("upload/CMS/files", name);
};

export const getPollHeaderImage = (name) => {
  return getMediaUrl("content/images_mysql/images_polls/backup", name);
};

export const getDocumentUrl = (name) => {
  return getMediaUrl("content/documents", name);
};
