import request from "@/api/request";

class GalleryService {
  static async getModelGalleries({ id }) {
    return request.get(`/galleries/${id}`);
  }

  static async getModelGallery({ modelId, galleryId }) {
    return request.get(`/galleries/${modelId}/${galleryId}`);
  }
}

export default GalleryService;
