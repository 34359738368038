import VideoService from "@/api/services/videoService";

export default () => {
  return {
    namespaced: true,
    state: {
      activeVideo: null,
      unlocked: null,
      unlockedBusy: false,
      videos: [],
      list: {},
      listBusy: false,
      lastPortion: null,
    },
    getters: {
      getActiveVideo: (state) => state.activeVideo,
      isUnlocked: (state) => (id) => state.unlocked !== null && state.unlocked.map((item) => item).indexOf(id) > -1,
      getUnlocked: (state) => state.unlocked,
      getVideo: (state) => (id) => state.videos[id],
      getList: (state) => state.list,
      isListBusy: (state) => state.listBusy,
      getLastPortion: (state) => state.lastPortion,
    },

    mutations: {
      SET_ACTIVE_VIDEO(state, videoId) {
        state.activeVideo = videoId;
      },
      SET_UNLOCKED(state, list) {
        state.unlocked = list;
      },
      SET_UNLOCKED_BUSY(state, value) {
        state.unlockedBusy = value;
      },
      SET_VIDEO(state, video) {
        const newVideo = {};

        if (state.videos[video.id]) {
          return;
        }

        newVideo[video.id] = video;

        state.videos = {
          ...state.videos,
          ...newVideo,
        };
      },
      SET_LIST_BUSY(state, value) {
        state.listBusy = value;
      },
      SET_LIST(state, list) {
        state.list = list;
      },
      SET_LAST_PORTION(state, value) {
        state.lastPortion = value;
      },
      INCREASE_COMMENT_COUNT(state, id) {
        const video = state.videos[id] ?? null;

        if (video === null) {
          return;
        }

        video.total_comments++;
      },
    },

    actions: {
      setActiveVideo({ commit }, { videoId }) {
        commit("SET_ACTIVE_VIDEO", videoId);
      },
      async getUnlocked({ commit, state }) {
        if (state.unlockedBusy) {
          return;
        }

        try {
          commit("SET_UNLOCKED_BUSY", true);
          const response = await VideoService.getUnlockedVideos();
          commit("SET_UNLOCKED", response);
        } catch (e) {
          commit("SET_UNLOCKED", []);
        }

        commit("SET_UNLOCKED_BUSY", false);
      },
      async getVideo({ dispatch }, id) {
        const response = await VideoService.getVideo(id);
        dispatch("setVideo", response);
      },
      async getList({ commit, state, dispatch }, params) {
        if (state.listBusy) {
          return;
        }

        if (JSON.stringify(params) === JSON.stringify(state.list.params)) {
          return;
        }

        commit("SET_LIST_BUSY", true);
        const response = await VideoService.getList(params);
        response.params = params;
        commit("SET_LIST", response);
        commit("SET_LIST_BUSY", false);

        response.videos.forEach((video) => {
          dispatch("setVideo", video);
        });
      },
      setVideo({ commit, state }, video) {
        if (state.videos[video.id]) {
          return;
        }

        commit("SET_VIDEO", video);
      },
      setLastPortion({ commit }, value) {
        commit("SET_LAST_PORTION", value);
      },

      increaseCommentCount({ commit }, { id }) {
        commit("INCREASE_COMMENT_COUNT", id);
      },
    },
  };
};
