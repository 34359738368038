<template>
  <div class="similar-videos">
    <h2>{{ $t("Watch more") }}</h2>

    <app-carousel
      v-if="videos.length"
      :items-to-show="2"
      :breakpoints="{
        480: {
          itemsToShow: 3,
        },
        768: {
          itemsToShow: 4,
        },
      }"
      navigation-theme="light"
      pagination-theme="light"
      @page-change="pageChange"
    >
      <template #default="{ slide }">
        <component :is="slide" v-for="video in videos" :key="video.id">
          <carousel-video-item :video="video" :show-title="true" @on-click="onItemClick(video)" />
        </component>
      </template>
    </app-carousel>
  </div>
</template>

<script>
import CarouselVideoItem from "@/components/carousel-video-item/index.vue";
import AppCarousel from "@/components/v2/app-carousel/index.vue";
import CarouselUpdateItem from "@/components/carousel-update-item/index.vue";
import CarouselModelItem from "@/components/carousel-model-item/index.vue";
import feedsService from "@/api/services/feedsService";
import featureService from "@/api/services/featureService";
import { FEATURE_PLAYER_PIP } from "@/utils/variables";
import { amplitudeTrack, amplitudeVideoTrack } from "@/utils/amplitude";

export default {
  name: "similar-videos",
  components: { CarouselModelItem, CarouselUpdateItem, AppCarousel, CarouselVideoItem },

  props: {
    videoId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },

  data() {
    return {
      videos: [],
    };
  },

  methods: {
    async load() {
      this.videos = await feedsService.getSimilar(this.videoId);
    },
    onItemClick(video) {
      featureService.trackAction(FEATURE_PLAYER_PIP, "similar_video_click");
      amplitudeVideoTrack(`Video play click`, video.id, {
        Location: "Video Player Feed - Watch more",
      });
    },
    pageChange() {
      amplitudeTrack("Video Player Feed slider changed");
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
