import i18n from "../i18n";

export const modelItemResizer = () => {
  document.querySelectorAll(".model-list, .upcoming-list").forEach((list) => {
    const allTitles = [];
    let rowTitles = [];
    let lastTopPosition;
    const items = list.querySelectorAll(".model-item");
    items.forEach((item) => {
      const topPosition = item.offsetTop;

      if (lastTopPosition !== undefined && lastTopPosition !== topPosition) {
        allTitles.push(rowTitles);
        rowTitles = [];
      }

      rowTitles.push(item.querySelector(".title"));
      lastTopPosition = topPosition;
    });

    if (rowTitles.length) {
      allTitles.push(rowTitles);
    }

    allTitles.forEach((titles) => {
      const maxHeight = Math.max.apply(
        null,
        titles.map((title) => {
          return title.scrollHeight;
        }),
      );

      if (maxHeight === 0) {
        return;
      }

      titles.forEach((title) => {
        title.style.height = maxHeight + "px";
      });
    });
  });
};

export const getModelName = (model) => {
  const lang = i18n.global.locale;
  const defaultLang = "en";
  return model["name_" + lang] || model["name_" + defaultLang];
};
